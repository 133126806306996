import React from 'react';

import { Pagination } from 'wix-ui-tpa/Pagination';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { ISearchResultsProps } from './SearchResults.types';
import { SearchInput } from '../SearchInput';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';
import { StatusLine } from '../StatusLine';
import { TabLine } from './TabLine';
import { FailedMessage } from '../ResultsStatus';
import { useRemount } from '../../hooks/useRemount';
import settingsParams from '../../../settingsParams';
import { Spec } from '../../../../../lib/specs';

import { st, classes } from './SearchResults.st.css';
import {
  ProductFacetsDialog,
  useProductFacetsDialog,
} from '../ProductFacetsDialog';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

export const SearchResults: React.FC<ISearchResultsProps> = ({
  apiErrorDetails,
  buildPageUrl,
  facets,
  failed,
  isDemoContent = false,
  isLoading = false,
  isPaginationHidden,
  isSearchBarEnabled,
  resultsCount,
  totalCount,
  sortProps,
  visibleTabsWithTitles,
  searchClearLabel,
  onQueryChange,
  onQueryClear,
  onSubmit,
  searchPlaceholder,
  searchQuery,
  activeTabIndex,
  onTabChange,
  lastQuery,
  demoContentNotificationText,
  children,
  currentPage,
  totalPages,
  onPageChange,
  onResetProductFacetsFilter,
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const { t } = useTranslation();

  const [
    isProductFacetsDialogOpen,
    openProductFacetsDialog,
    closeProductFacetsDialog,
  ] = useProductFacetsDialog();

  const paginationAlignment = settings.get(settingsParams.paginationAlignment);

  // This is a hack. It will be removed when issues regarding experiments in SSR are fixed
  const key = useRemount();

  const showDesktopFacets = !!facets && !failed && !isMobile;
  const showMobileFacets = !!facets && !failed && isMobile;

  return (
    <div
      key={key}
      data-hook="search-results-root"
      className={st(classes.root, {
        fluid: isEditorX,
        mobileView: isMobile,
        withDirection: experiments.enabled(Spec.WidgetRtl),
      })}
    >
      {isSearchBarEnabled && (
        <SearchInput
          clearLabel={searchClearLabel}
          onChange={onQueryChange}
          onClear={onQueryClear}
          onSubmit={onSubmit}
          placeholder={searchPlaceholder}
          value={searchQuery}
        />
      )}
      <div data-hook="content-wrapper" className={classes.contentWrapper}>
        <div
          data-hook="content"
          className={st(classes.content, { loading: isLoading })}
        >
          {visibleTabsWithTitles.length > 1 && (
            <TabLine
              activeTabIndex={activeTabIndex}
              items={visibleTabsWithTitles}
              onTabClick={onTabChange}
            />
          )}

          <div className={classes.layout}>
            {showDesktopFacets && (
              <div className={classes.facets}>{facets}</div>
            )}
            <div className={classes.items}>
              {showMobileFacets && (
                <Button
                  className={classes.facetsButton}
                  priority={PRIORITY.basicSecondary}
                  onClick={openProductFacetsDialog}
                  upgrade
                  fullWidth
                >
                  {t('searchResults.facets.mobile.openFilterButton.label')}
                </Button>
              )}
              {failed ? (
                <FailedMessage
                  isMobile={isMobile}
                  apiErrorDetails={apiErrorDetails}
                />
              ) : (
                <StatusLine
                  totalCount={totalCount}
                  resultsCount={resultsCount}
                  facetsEnabled={!!facets}
                  searchQuery={lastQuery}
                  sortProps={sortProps}
                />
              )}

              {isDemoContent ? (
                <DemoContentNotification text={demoContentNotificationText} />
              ) : null}
              {children}

              {currentPage && totalPages && !isPaginationHidden && (
                <div
                  className={st(classes.paginationWrapper, {
                    alignment: paginationAlignment,
                  })}
                >
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    maxPagesToShow={
                      isMobile
                        ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
                        : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
                    }
                    pageUrl={buildPageUrl}
                    onChange={onPageChange}
                    data-hook="pagination"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Spinner dataHook="spinner" />}
        {isProductFacetsDialogOpen && (
          <ProductFacetsDialog
            onClose={closeProductFacetsDialog}
            onReset={() => onResetProductFacetsFilter()}
            isLoading={isLoading}
          >
            {facets}
          </ProductFacetsDialog>
        )}
      </div>
    </div>
  );
};
