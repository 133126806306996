import React, { FC } from 'react';

import { st, classes } from './ProductFacet.st.css';

interface ProductFacetProps {
  title: string;
  noTopGap: boolean;
}

export const ProductFacet: FC<ProductFacetProps> = ({
  title,
  noTopGap,
  children,
}) => {
  return (
    <>
      <div className={st(classes.subtitle, { noTopGap })}>{title}</div>
      {children}
    </>
  );
};
